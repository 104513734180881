export function useUi() {
    function formatDate(date: string | null | undefined, type?: string) {
        if (!date)
            return 'Onbekend'

        const lang = 'nl-NL'
        const config: Intl.DateTimeFormatOptions = {}

        const dateObj = new Date(date)

        if (type === 'expiration') {
            // Format: yyyy-mm-dd (2025-03-12)
            const year = dateObj.getFullYear()
            const month = String(dateObj.getMonth() + 1).padStart(2, '0')
            const day = String(dateObj.getDate()).padStart(2, '0')
            return `${year}-${month}-${day}`
        }

        if (type === 'short') {
            // Format: 12-mrt-2025
            const config: Intl.DateTimeFormatOptions = {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
            }
            return dateObj.toLocaleDateString(lang, config).replace(/\s+/g, '-')
        }

        if (type === 'month-long') {
            // 1 januari 2025
            config.year = 'numeric'
            config.month = 'long'
            config.day = 'numeric'
        } else if (type === 'weekday-long') {
            // Maandag 1 januari
            config.weekday = 'long'
            config.day = 'numeric'
            config.month = 'long'
        } else {
            // Default format: dd-mm-yyyy
            config.day = '2-digit'
            config.month = '2-digit'
            config.year = 'numeric'
        }

        return dateObj.toLocaleDateString(lang, config)
    }

    return {
        formatDate,
    }
}
